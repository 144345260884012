import { HOC_MDXWrapper } from 'nextra/setup-page'
import { pageMap } from '/home/runner1/actions-runner/_work/Astrolab/Astrolab/frontend/web/landing/.next/static/chunks/nextra-page-map-en.mjs'

/*@jsxRuntime automatic*/
/*@jsxImportSource react*/
import {useMDXComponents as _provideComponents} from "nextra/mdx";
const title = "The OctoBot Blog, follow the latest news";
const frontMatter = {
  "searchable": false,
  "description": "Follow regular guides, updates and stories on OctoBot. Profit the most of your crypto investment strategies robot by being the first traders to discover our latest tips and future plans.",
  "title": "The OctoBot Blog, follow the latest news"
};
import {Blog} from 'pages_components/blog';
export function useTOC(props) {
  return [];
}
function MDXLayout(props) {
  return <Blog title="OctoBot Blog" featuredArticles="Featured articles" latestArticles="Latest articles" />;
}


export default HOC_MDXWrapper(
  MDXLayout,
  '/en/blog',
  {"filePath":"pages/en/blog.mdx","timestamp":1732702106000,pageMap,frontMatter,title},
  typeof RemoteContent === 'undefined' ? useTOC : RemoteContent.useTOC
)